<template>
  <div>
    <el-dialog
	  title="编辑欠费规则"
      :visible.sync="dialogVisible"
      width="700px"
      :close-on-click-modal='false'
      custom-class='dialog'
	  @close='resetForm'
	>
		<el-form ref="form" :model="form" label-width="140px" :rules="rules" style='padding:0'>
			<!-- <el-form-item :label="$t('searchModule.Vehicle_type')" prop='carType'>
				 <el-select v-model="form.carType" placeholder="请选择" @change="getCarGroupList" disabled>
					<el-option :value="0" label="小型车">小型车</el-option>
					<el-option :value="2" label="大型车">大型车</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="车辆组" prop='parkCarGroupId'>
				 <el-select v-model="form.parkCarGroupId" placeholder="请选择" multiple collapse-tags>
					 <el-option
					   :label="value.parkCarGroupName"
					   :value="value.parkCarGroupId"
					   v-for='value in carGroupList'
					   :key='value.parkCarGroupId'
					  >
					  </el-option>
				</el-select>
			</el-form-item>				 -->
			<el-form-item label="欠费规则" prop='debtLeaveRuleId'>
				<el-select v-model="form.debtLeaveRuleId" placeholder="请选择">
					   <el-option
					     :label="value.debtLeaveRuleName"
						 :value="value.debtLeaveRuleId"
						 v-for='value in feeList'
						 :key='value.debtLeaveRuleCode'
					   >
					   </el-option>
				</el-select>
			</el-form-item>
		</el-form>
        <div slot="footer">
          <el-button type="primary" @click="save" :loading="saveLoading">{{ $t('button.preservation') }}</el-button>
          <el-button @click="resetForm">取 消</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      debtLeaveRuleId: '',
      form: {
	    //  carType: '',
	    //  parkCarGroupId: [],
	     debtLeaveRuleId: ''
      },
	  feeList: [],
	  rules: {
		   debtLeaveRuleId: {
			   required: true,
			   message: '请选择欠费规则',
			   trigger: 'change'
		   }
	  },
	  saveLoading: false
    }
  },
  methods: {
    // 打开窗口
    open() {
       this.dialogVisible = true;
	   this.form.debtLeaveRuleId = '';
	   this.getFeeList();
    },
    // 保存
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
		  let parms = {}
		//   parms.parkCarGroupId = this.form.parkCarGroupId.join(',');
		  parms.debtLeaveRuleId = this.form.debtLeaveRuleId;
		  this.saveLoading = true;
          let url = '/acb/2.0/bacb/parkDebtLeaveRule/update';
          this.$axios.post(url, {
            data: {
              parkId: this.$route.query.parkId,
              ...parms
            }
          }).then(res => {
            if (res.state == 0) {
			   setTimeout(() => {
		           this.saveLoading = false;
				   this.dialogVisible = false;
				   this.$emit('searchData');
			   }, 2000)
            } else {
			   this.saveLoading = false;
               this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine')
               })
            }
          }).catch(() => {
		    this.saveLoading = false;
		  })
        } else {
          return false;
        }
      });
    },
	// 获取详情
    getDetail(debtLeaveRuleId) {
	  // console.log('对应ID:' + id)
      this.form.debtLeaveRuleId = debtLeaveRuleId;
    },

   // 查询计费规则
   getFeeList () {
		// console.log(2)
		this.feeList = [];
		let url = '/acb/2.0/bacb/debtLeaveRule/list';
		this.$axios.get(url, {
			data: {
				operationId: this.$route.query.operationId,
				pageNum: 1,
                pageSize: 100
			}
		}).then(res => {
			if (res.state == 0) {
				this.feeList = res.value.list || [];
			} else {
				this.$alert(res.desc, this.$t('pop_up.Tips'), {
					confirmButtonText: this.$t('pop_up.Determine')
				 })
			}
		})
   },

   // 取消
   resetForm () {
	  this.dialogVisible = false;
	  this.$refs.form.resetFields();
   }
  },
  created() {
  }
}
</script>
<style>
.formWidth {
  width: 221.5px;
}

</style>

