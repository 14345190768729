<template>
    <div>
        <el-dialog
            title="添加欠费规则"
            :visible.sync="dialogVisible"
            width="700px"
            :close-on-click-modal='false'
            custom-class='dialog'
			@close='resetForm'
            >
            <el-form ref="form" :model="form" label-width="140px" :rules="rules" style='padding:0'>
                <!-- <el-form-item :label="$t('searchModule.Vehicle_type')" prop='carType'>
                     <el-select v-model="form.carType" placeholder="请选择" @change="getCarGroupList">
						<el-option :value="0" label="小型车">小型车</el-option>
						<el-option :value="2" label="大型车">大型车</el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="车辆组" prop='parkCarGroupId'>
                     <el-select v-model="form.parkCarGroupId" placeholder="请选择" multiple collapse-tags>
                         <el-option
						   :label="value.parkCarGroupName"
						   :value="value.parkCarGroupId"
						   v-for='value in carGroupList'
						   :key='value.parkCarGroupId'
						 >
						 </el-option>
                    </el-select>
                </el-form-item>				 -->
                <el-form-item label="欠费规则" prop='debtLeaveRuleId'>
                    <el-select v-model="form.debtLeaveRuleId" placeholder="请选择">
                           <el-option
						     :label="value.debtLeaveRuleName"
							 :value="value.debtLeaveRuleId"
							 v-for='value in feeList'
							 :key='value.debtLeaveRuleCode'
						    >
							</el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button type="primary" @click="save" :loading="saveLoading">{{ $t('button.preservation') }}</el-button>
                <el-button @click="resetForm">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
   data () {
       return {
           dialogVisible: false,
           form: {
            //    carType: '',
            //    parkCarGroupId: [],
               debtLeaveRuleId: ''
           },
           feeList: [],
		   carGroupList: [],
           rules: {
            //    carType: {
            //        required: true,
            //        message: '请选择车辆类型',
            //        trigger: 'change'
            //    },
            //    parkCarGroupId: {
			//        type: 'array',
            //        required: true,
            //        message: '请选择车辆组',
            //        trigger: 'change'
            //    },
               debtLeaveRuleId: {
                   required: true,
                   message: '请选择欠费规则',
                   trigger: 'change'
               }
           },
		   saveLoading: false
       }
   },
   methods: {
       // 打开窗口
       open () {
           this.dialogVisible = true;
           this.form.carType = '';
		   this.form.parkCarGroupId = [];
		   this.form.debtLeaveRuleId = '';
		   // this.getCarGroupList();
           this.getFeeList();
       },

	   // 保存
       save () {
           this.$refs.form.validate((valid) => {
                if (valid) {
				    let parms = {}
					// parms.carType = this.form.carType;
				    // parms.parkCarGroupId = this.form.parkCarGroupId.join(',');
					parms.debtLeaveRuleId = this.form.debtLeaveRuleId;

					// console.log('提交分析:' + JSON.stringify(parms))
					this.saveLoading = true;
					let url = '/acb/2.0/bacb/parkDebtLeaveRule/add';
					this.$axios.post(url, {
						data: {
							parkId: this.$route.query.parkId,
							// operationId: this.$route.query.operationId,
							...parms
						}
					}).then(res => {
						if (res.state == 0) {
						    setTimeout(() => {
							    this.saveLoading = false;
								this.dialogVisible = false;
								this.$emit('searchData');
							}, 2000)
						} else {
						    this.saveLoading = false;
							this.$alert(res.desc, this.$t('pop_up.Tips'), {
								confirmButtonText: this.$t('pop_up.Determine')
							})
						}
					}).catch(() => {
						this.saveLoading = false;
					})
                } else {
                    return false;
                }
           })
       },

	   // 查询车辆组
	   getCarGroupList(val) {
	      // console.log('选中值:' + val)
		  this.form.parkCarGroupId = [];
		  this.carGroupList = [];
		  this.$refs.form.clearValidate('parkCarGroupId');

		  let url = '/acb/2.0/bacb/msfeeScene/listParkCarGroup';
		  this.$axios.get(url, {
			data: {
			  parkId: this.$route.query.parkId,
			  carType: val
			}
		  }).then(res => {
			if (res.state == 0) {
			  this.carGroupList = res.value || [];
			} else {
			  this.$alert(res.desc, this.$t('pop_up.Tips'), {
				confirmButtonText: this.$t('pop_up.Determine')
			  })
			}
		  })
	   },

	   // 查询计费规则
       getFeeList () {
	   		// console.log(2)
			this.feeList = [];
            let url = '/acb/2.0/bacb/debtLeaveRule/list';
            this.$axios.get(url, {
                data: {
                    operationId: this.$route.query.operationId,
                    pageNum: 1,
                    pageSize: 100
                }
            }).then(res => {
				if (res.state == 0) {
					this.feeList = res.value.list || [];
				} else {
					this.$alert(res.desc, this.$t('pop_up.Tips'), {
						confirmButtonText: this.$t('pop_up.Determine')
					 })
				}
            })
       },

	   // 取消
       resetForm () {
          this.dialogVisible = false;
		  this.$refs.form.resetFields();
       }
   },
   created () {
   }
}
</script>
<style>
    .formWidth{
        width:221.5px;
    }

</style>

